import * as React from "react";
import Layout from "../components/layout";
import SocialProof from "../components/homePage/socialProof";
import Activation from "../components/activation";
import { Container } from "react-bootstrap";
import { section } from "./case-studies.module.css";

// markup
const CaseStudiesPage = () => {
  return (
    <Layout pageTitle="Case studies">
      <Container fluid className={section}>
        <SocialProof />
      </Container>

      <Activation
        title="The #1 Yacht, Fleet and Charter Management Solution"
        buttonText="Request a demo"
      />
    </Layout>
  );
};

export default CaseStudiesPage;
